<template>
    <div class="list-manage personal-info">
        <section class="list-manage-table">
            <div class="table-title"><span class="title">个人信息</span>
                <span class="btn btn-orange" @click="szmm">设置密码</span>
                <el-dialog title="设置密码" :visible.sync="szmm_show" width="30%" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
                    <div class="form-item">
                        <div class="form-label">旧密码</div>
                        <el-input  type="password" v-model="user_set_password.old_password" placeholder="请输入密码…" style="width: 350px;"></el-input>
                    </div>
                    <div class="form-item">
                        <div class="form-label">新密码</div>
                        <el-input type="password" v-model="user_set_password.password" placeholder="请输入密码…" style="width: 350px;"></el-input>
                    </div>
                    <div class="form-item">
                        <div class="form-label">重复密码</div>
                        <el-input type="password" v-model="user_set_password.rpassword" placeholder="请输入重复密码…" style="width: 350px;"></el-input>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="szmm_show = false">取 消</el-button>
                        <el-button type="primary" @click="editinfo_password">确 定</el-button>
                    </span>
                </el-dialog>
            </div>
            <div class="personal-info-form" v-loading="loading">
                <div class="form-item">
                    <div class="form-label">当前头像</div>
                    <el-upload class="current-avatar" action="http://114.67.170.224:21880/api/upload" :multiple="false" :on-success="changeimg" drag>
                        <img v-if="user.consumer_head != null" :src="user.consumer_head" alt="" width="80px" height="80px">
                        <img v-else src="~@/assets/images/temp/temp_04.png" alt="" width="80px" height="80px">
                        <span class="edit-label">编辑头像</span>
                    </el-upload>
                </div>
                <div class="form-item">
                    <div class="form-label">姓名</div>
                    <el-input v-model="edit.consumer_name" placeholder="请输入名称…" style="width: 350px;" clearable></el-input>
                </div>
                <div class="form-item">
                    <div class="form-label">性别</div>
                    <div class="radio">
                        <el-radio v-model="radio" :label="0">男</el-radio>
                        <el-radio v-model="radio" :label="1">女</el-radio>
                    </div>
                </div>
                <span class="btn btn-orange" @click="editinfo()">保存修改</span>
            </div>

        </section>

    </div>
</template>

<script>
import { user_edit, user_find, user_edit_password } from '@/api/contract'
import kits from "@/kits"
import { getuser } from "@/api/user"
// width="30%" append-to-body
export default {
    data() {
        return {
            szmm_show: false,

            radio: '1',
            input: '',
            id: null,
            user_set_password: {
                old_password: "",
                password: "",
                rpassword: "",
            },
            user: {
                id: null,
                consumer_name: null,
                mobile: null,
                consumer_head: null,
                sex: null
            },
            edit: {
                id: null,
                consumer_name: null,
                mobile: null,
                consumer_head: null,
                sex: null
            },
            loading: true
        };
    },
    created() {
        this.id = getuser();
        this.getuserinfo();

    },
    mounted() {
        // this.szmm_show = true
        // setTimeout(() => {
        //     this.szmm_show = false
        // }, 1);
    },
    methods: {
        getuserinfo() {
            user_find({ "id": this.id.id }).then(res => {
                this.user = res.data
                console.log(res);
                this.edit.id = this.user.id
                this.edit.mobile = this.user.mobile
                this.edit.consumer_name = this.user.consumer_name
                this.edit.consumer_head = this.user.consumer_head
                this.user.consumer_head = kits.img.url(this.user.consumer_head)
                this.radio = this.user.sex
                this.edit.sex = this.radio
                console.log('a', this.edit);
                this.loading = false
            });
        },
        // 修改信息
        editinfo() {

            if (this.password != "") {
                if (this.password.length < 6) {
                    this.$message({
                        message: '密码必须大于等于6位数',
                        type: 'error'
                    })
                    return
                }
                if (this.password != this.rpassword) {
                    this.$message({
                        message: '重复密码错误！',
                        type: 'error'
                    })
                    return
                } else {
                    this.edit.consumer_password = this.password
                }
            }
            console.log("xxxxxxxxxx this.edit", this.edit)
            user_edit(this.edit)
                .then(res => {
                    if (res.data == 1) {
                        this.$message({
                            message: '修改成功！',
                            type: 'success'
                        })
                        this.getuserinfo()
                        location.reload()
                    }
                })
                .catch((err) => {
                    // 出错时要做的事情
                    console.log(err);
                });
        },
        changeimg(res) {
            this.edit.consumer_head = res.data.value
            this.user.consumer_head = kits.img.url(res.data.value)
        },
        szmm() {
            this.szmm_show = true
        },
        // 修改密码
        editinfo_password() {

            if (this.user_set_password.old_password == "") {
                this.$message({
                    message: '旧密码不能为空',
                    type: 'error'
                })
                return
            }
            if (this.user_set_password.password.length < 6) {
                this.$message({
                    message: '密码必须大于等于6位数',
                    type: 'error'
                })
                return
            }
            if (this.user_set_password.password != this.user_set_password.rpassword) {
                this.$message({
                    message: '重复密码错误！',
                    type: 'error'
                })
                return
            }

            console.log("xxxxxxxxxx this.user_set_password", this.user_set_password)
            user_edit_password({ old_password: this.user_set_password.old_password, consumer_password: this.user_set_password.password })
                .then(res => {
                    if (res.data == 1) {
                        this.$message({
                            message: '修改成功！',
                            type: 'success'
                        })
                        this.szmm_show = false
                    }
                })
                .catch((err) => {
                    console.log("user_edit_passwordxxxxxxxxerr====",err);
                    // 出错时要做的事情
                    this.$message({
                        message: `${err}`,
                        type: "error",
                    });
                  
                });
        },
    }
}
</script>

<style scoped>
/deep/ .el-upload .el-upload-dragger {
    width: 80px;
    height: 80px;
    border: none;
}
</style>
